
.content {
	.user {
		display: grid;
		grid-template-columns: 120px 1fr;

		.img {
			width: 100px;
			height: 100px;
			margin-bottom: 20px;
			border-radius: 50%;
			display: block;
			background-size: cover;
			background-position: center top;
		}
		.info {
			span {
				width: 80px;
				display: inline-block;
			}
		}
	}
}
